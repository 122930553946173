<template>
    <div>
        <div v-if="localPage === 'list'">
            <typeRealEstateList :item="item" :content="content" @changePage="changePage" @setType="setType"></typeRealEstateList>
        </div>
        <div v-if="localPage === 'detail'">
            <typeRealEstateDetail :type='type' :item="item" :content="content" @changePage="changePage"></typeRealEstateDetail>
        </div>
    </div>
</template>

<script>
    import {mapState,mapMutations,mapActions} from "vuex"
  export default {
    name: "typeRealEstate",
    components:{
      typeRealEstateList:() => import("./typeRealEstate/typeRealEstateList"),
      typeRealEstateDetail:() => import("./typeRealEstate/typeRealEstateDetail")
    },
    data(){
      return{
        type:'create'
      }
    },
    props:['item','content'],
    computed:{
        ...mapState('realEstate',['localPage','realEstate']),
    },
    methods:{
        ...mapMutations('realEstate',['setLocalPage']),
        ...mapActions('realEstate',['getRealEstates']),
        changePage(page){
          this.setLocalPage(page);
        },
          setType(type){
           this.type = type;
          }
    },
    async mounted() {
      const payload = {
        hotel_id:this.$route.params.id,
        menu_id:this.item.id
      };
      await this.getRealEstates(payload);
    }
  }
</script>

<style scoped>

</style>
